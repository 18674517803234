import {BiHomeAlt2} from 'react-icons/bi'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {LuBrainCircuit} from 'react-icons/lu'
import {BiDownload} from 'react-icons/bi'

export const SIDEBAR_LINKS=[

    {
        key:'home',
        label:'Home',
        path:'/',
        icon:<BiHomeAlt2/>
    },
    {
        key:'documentation',
        label:'Documentation',
        path:'/documentation',
        icon:<LuBrainCircuit/>
    },
    {
        key:'download',
        label:'Downloads',
        path:'/download',
        icon:<BiDownload/>
    }
]

export const SIDEBAR_BOTTOM_LINKS=[
    {
        key:'aboutus',
        label:'About us',
        path:'/about',
        icon:<AiOutlineInfoCircle/>
    }
]