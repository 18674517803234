import React from 'react'
import {AiFillLinkedin} from 'react-icons/ai'
import { Link } from 'react-router-dom'

export default function About() {
  return (
    <div className=' flex h-full w-full align-middle justify-center items-center'>
        <div className=' grid grid-cols-2 gap-10'>
                    <div className=' grid-cols-1'>
                        <Link to={"https://es.linkedin.com/in/brais-iglesias-otero-475897214"} target='_blank'> <AiFillLinkedin size={70}/>  </Link>
                    </div>
        </div>
    </div>
  )
}
