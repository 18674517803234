import React from 'react';

export default function DownloadTable() {

  const versions=[
    {
        number:'1.0.0',
        releaseDate:'29/01/2024',
        os:'Windows'
    }
    ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-center">
        <table className="min-w-fit bg-white border border-gray-200 table-auto">
          <thead>
            <tr>
              <th className="w-1/3 py-1  border-b border-gray-200 bg-gray-100">Version</th>
              <th className="w-1/3 py-2 border-b border-gray-200 bg-gray-100">Release date</th>
              <th className="w-1/3 py-2  border-b border-gray-200 bg-gray-100">OS</th>
            </tr>
          </thead>
          <tbody>
            {versions.map((item, index) => (
              <tr key={index} className="text-center">
                <td className="w-48 py-2  border-b border-gray-200">{item.number}</td>
                <td className="w-48 py-2  border-b border-gray-200">{item.releaseDate}</td>
                <td className="w-48 py-2  border-b border-gray-200">{item.os}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
