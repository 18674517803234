import './App.css';
import Layout  from './components/Layout';
import Documentation from './components/features/documentation/Documentation';
import Home from './components/Home';
import About from './components/features/layout/About';
import Download from './components/features/downloads/Download';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";


function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<Layout/>}>
            <Route index element={<Home/>}></Route> 
            <Route path='documentation' element={<Documentation/>}></Route> 
            <Route path='about' element={<About/>}></Route> 
            <Route path='download' element={<Download/>}></Route> 
          </Route>
        </Routes>
    </Router>
    
  );
}

export default App;
