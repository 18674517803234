

export interface DocSection{
    index: number,
    title: string;
    description: string;
}

export const GETTING_STARTED: DocSection ={
    index:0,
    title:'Getting started',
    description:'First of all, you will have to load your data into the program. For that you have two options: <ul>'+
    ' <li> - Load an existing file <b>(.csv or .xlsx)</b>. </li>'+
    ' <li> - Create your own data set from scratch. </li> </ul>'
}
export const CLEANIN_DATA: DocSection ={
    index:1,
    title:'Cleaning data',
    description:'This options will allow you to get rid of the outliers and the missing values of your data.<br><br>'+
    'Regarding the <b>missing values</b> you will be able to delete them or select a strategy to substitute them for the mean o the median of the variable in which they are.<br><br>'+
    'When it comes to <b>outliers</b>, the options are the following:<br><ul>'+
    ' <li class="outliers">- Highlight them. The default color <svg class="color_outliers" width="20" height="20"><rect width="20" height="20" style="fill:#E9E612;stroke-width:3;stroke:rgb(0,0,0)"/></svg> but it can be changed </li>'+
    ' <li> - Delete them. </li>'+
    ' <li> - Substitute them for the mean, median or the closer quartile. </li>'+
    '</ul>'
}
export const PROCESS_DATA: DocSection ={
    index:2,
    title: 'Preprocessing options',
    description:'For preprocessing the data the following options are available.<br><br>'+
    'For <b>numerical</b> variables:<br>'+
    ' - <a href="https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.MinMaxScaler.html"><u>Normalization minmax</u></a> <br>'+
    ' - <a href="https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.QuantileTransformer.html"><u>Quantile Scaler</u></a> <br>'+
    ' - <a href="https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.RobustScaler.html"><u>Robust scaler</u></a> <br>'+
    ' - Discretization: automatic, custom number of bins and custom ranges.'+
    '<br><br>For <b>nominal</b> variables:<br>'+
    ' - <a href="https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.OneHotEncoder.html"><u>One hot encoding</u></a> <br>'+
    ' - <a href="https://scikit-learn.org/stable/modules/generated/sklearn.preprocessing.LabelEncoder.html"><u>Label encoding</u></a> <br>',
}
export const PLOT_DATA: DocSection ={
    index:3,
    title:'Plotting data',
    description:'With the program you can generate the following graphs:<br><br>'+
    ' - 2D Graph with with X and Y axis selected variables. Two variables can be printed in Y axis.<br>'+
    ' - 3D surface Graph.<br>'+
    ' - Histogram.<br>'+
    ' - Boxplot. <br>'+
    ' - Fitted regression line. <br>'+
    ' - Heatmap with correlation and covariance matrix. <br>'+
    ' - Pairplots. <br>'
}

export const STATISTICAL_TEST: DocSection ={
    index:4,
    title:'Statistical tests',
    description:'This program allows you to apply statistical test to your data and check the results.<br><br>'+
    '<b>Single variable tests:</b> normality.<br>'+
    '<b>Multiple variable tests:</b> ANOVA, Kruskall Wallis, Pearson, T Student.<br>'+

    '<b>Automatic test:</b> this option performs an atomatic test and display the variables that show a normal distribution, the correlations between them and'+
    ' check the statistical difference between groups in case of existing categorical variables.'
}

export const RULES: DocSection ={
    index:5,
    title:'Generating rules',
    description:'The main feature of this program is to give the users the chance to generate human readable rules from their data.'+
    ' This rules have the format:<br><br><b> IF X IS LOW THEN Y IS HIGH (0.9) AND Y IS LOW (0.1)</b> <br><br>'+
    'The number of inputs of each rule (2 by default) can be changed by the user, as well as the membership functions (2 by default, HIGH and LOW).<br>'+
    'When a neurofuzzy system is trained, the rules are calculated for each input separatly and for the combinations of the maximum number of inputs per rule selected.'
}

export const PREDICTION_MODEL: DocSection ={
    index:6,
    title:'Generating predicting models',
    description:'The program allows the user to train a set of the most popular machine learning algorithms, both for classification and regression.'+
    'Some of the available models for training are Support Vector Machine, Random Forest, Multilayer Perceptron .... For this section, the well known library '+
    ' <a href="https://scikit-learn.org/stable/"><u>Scikit-Learn</u></a> is used.'
}

export const DocSections: DocSection[]=[
    GETTING_STARTED,
    CLEANIN_DATA,
    PROCESS_DATA,
    PLOT_DATA,
    STATISTICAL_TEST,
    RULES,
    PREDICTION_MODEL,
]