import React from 'react'
import logo from '../resources/logo_128x128.png'
import {ScrollView} from 'react-native'
import YoutubeResponsive from './features/video/YoutubeResponsive'

import '../styles/style.css'
import PoweredBy from './features/documentation/PoweredBy'

export default function Home() {

  return (
    <ScrollView className='flex h-full w-full justify-center'>
        
        <div className=' grid  home_div'>
            <div className=' flex align-middle items-center justify-center text-center home_title_div'>
                <div className=' flex justify-center items-center home_icon'>
                    <img className="" src={logo} alt='logo'></img>
                </div>
                    
                <span className=' text-7xl font-bold home_text'>Neurorule</span>
            </div>
                
            <div className=' grid gap-12'>
                    
                <div className=' grid grid-cols-8 h-min'>
                    <div className=' col-start-3 col-span-4 text-center'>
                        <span className=' text-xl font-bold'>What is <span className=' text-[#6CA4F7]'>Neurorule</span>?</span>
                        <p className=' description_text'>
                            Neurorule is sophisticated software engineered for efficient data <b>mining</b>, 
                            facilitating comprehensive data <b>analysis</b> and informed business decision-making. 
                            This application seamlessly integrates artificial intelligence capabilities into data processing workflows, 
                            offering a user-friendly experience devoid of the necessity for intricate coding expertise. 
                            Tailored towards <b>scientific professionals</b> seeking to harness the power of AI within their respective domains, 
                            Neurorule eliminates the barriers posed by software proficiency and time constraints.

                            At its core, Neurorule boasts a robust neural network, 
                            yielding a collection of intelligible rules that empower users with effortless interpretation and actionable insights.
                        </p>
                    </div>
                </div>

                <div className=' grid grid-cols-8 h-min'>
                    <div className=' col-start-3 col-span-4 text-center'>
                        <span className=' text-xl font-bold'>How and where could I use <span className=' text-[#6CA4F7]'>Neurorule</span>?</span>
                        <p className=' description_text'>The potential of this software is geared towards field optimization across various domains, including plant biology or the pharmaceutical industry, addressing low-scale business challenges, 
                                and any process necessitating comprehensive data analysis. </p>
                    </div>
                </div>
                <div className=' grid grid-cols-8 h-min'>
                    <div className=' col-start-3 col-span-4 text-center'>
                    <span className=' text-xl font-bold'>Here is a link to the <a href='https://drive.google.com/file/d/19gdi40lxF7xeX1hlG3XK_yr2H7nmYbvA/view?usp=sharing' rel="noreferrer" target='_blank'><span className=' text-[#6CA4F7]'>documentation</span></a> of the project</span>
                    </div>
                </div>
                    
                

                <div className=' grid grid-cols-8 final_div'>
                    <div className=' col-start-3 col-span-4 text-center'>
                        <span className=' text-xl font-bold mb-4'>Watch this tutorial</span>
                        <div className=' flex justify-center items-center'>
                            <YoutubeResponsive ></YoutubeResponsive>
                        </div>
                    </div>
                </div>     
                <PoweredBy></PoweredBy>   
                          
            </div>
        </div>
     
    </ScrollView>
  )
}


