import React from 'react'
import {AiFillWindows} from 'react-icons/ai'
import '../../../styles/style.css'
import { ScrollView } from 'react-native'
import { Link } from "react-router-dom";
import { ZIP_FILE,INSTALLER_FILE } from '../../../const/endpoints.ts';
import DownloadTable from './DownloadTable.jsx';


export default function Download() {

  return (
    <ScrollView className=' flex h-full w-full justify-center'>
        <div className=' grid grid-rows-2 grid_mobile'>
            

            <div className=' self-center download_text'>
                <span className='text-5xl text_mobile'> Download <span className=' font-bold'>Neuro</span><span className='text-[#6CA4F7]'>rule</span> desktop application</span>
                
            </div>
            
            <div className=' grid grid-cols-3 download_icons'>
                <div>
                    {/* <Link className=' download_icons_a'> <DiLinux size={120}/></Link> */}
                    
                </div>
                <div className='download_div'>
                    <Link className=' download_icons_a' to={INSTALLER_FILE} target='_blank' download> <AiFillWindows size={120}/></Link>

                    <a href={ZIP_FILE}  target='_blank' rel="noreferrer"><u>Link to zip file</u></a>
                </div>
                <div>
                    {/* <Link className=' download_icons_a'> <AiFillApple size={120}/></Link> */}
                </div>
            </div>
            <div className=' text-center m-5 text-xl'>
                <i>Current version 1.0.0</i>
            </div>
            <div>
                <DownloadTable></DownloadTable>
            </div>
        </div>
    </ScrollView>
  )
}
