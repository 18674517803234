import React from 'react';
import {ScrollView} from 'react-native';
import Section from './Section.jsx';
import { DocSections } from '../../../const/documentation-const.ts';

export default function Documentation() {

  return (
	<ScrollView className=' grid grid-rows'>
		{DocSections.map((section, index) => (
					<Section title={section.title} description={section.description} key={index}></Section>

		))}
	</ScrollView>
  )
}
