import React from 'react'
import logo from '../../../resources/logo_50x50.png'
import { SIDEBAR_BOTTOM_LINKS, SIDEBAR_LINKS } from '../../navigation'
import { Link } from 'react-router-dom'

const linkClasses="flex items-center gap-2 font-light px-3 py-2 rounded-lg hover:bg-[#6CA4F7] hover:no-underline hover:rounded-full active: bg-neutral-800 text-base m-3"

export default function Sidebar() {
  return (
    <div className="bg-zinc-700 w-45 p-3 flex flex-col text-white sidebar">
        <div className='flex items-center gap-2 px-1 py-3 justify-center'>
            <img className="" src={logo} alt="Logo"/>
            <span className=' text-neutral-100 font-bold'></span>
        </div>
        <div className=' flex-1'>
                {SIDEBAR_LINKS.map((item) => (
                    <SidebarLink key={item.key}
                        item={item}/>

                ))}
        </div>
        <div>
                {SIDEBAR_BOTTOM_LINKS.map((item) => (
                    <SidebarLink key={item.key}
                        item={item}/>

                ))}
        </div>
        
    </div>
  )
}


function SidebarLink({item}){
    return(
        <Link to={item.path} className={linkClasses}>
            <span className=' text-xl'>{item.icon}</span>
            <span className=' navigation_link'>{item.label}</span>
        </Link>
    )
}