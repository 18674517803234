import {React, useState, useEffect } from 'react'
import { VIDEO_URL } from '../../../const/endpoints.ts'

const DEFAULT_SIZE={
  width: '520',
  height: '300'
}
const MEDIUM_SIZE={
  width: '340',
  height: '235'
}
const SMALL_SIZE={
  width: '260',
  height: '160'
}

export default function YoutubeResponsive() {


  const [videoSize, setWindowSize] = useState(()=>{
    const windowWidth=window.innerWidth;

    if (windowWidth<500){
      return (SMALL_SIZE);    
    }
    else if (windowWidth<900){
      return (MEDIUM_SIZE);
    }else{
      return (DEFAULT_SIZE);
    }
  });  


  useEffect(() => {

    const updateScreen = (()=>{
      const windowWidth=window.innerWidth;
  
      if (windowWidth<500){
        setWindowSize(SMALL_SIZE);    
      }
      else if (windowWidth<900){
        setWindowSize(MEDIUM_SIZE);
      }else{
        setWindowSize(DEFAULT_SIZE);
      }
  
    })

    window.addEventListener('resize', updateScreen);

    return () => {
      window.removeEventListener('resize', updateScreen);
    };
  }, []);


  return (
    <div>
        <iframe id="player" type="text/html" width={videoSize.width} height={videoSize.height}
        src={VIDEO_URL}
        frameBorder="0" title='Demo Neurorule'></iframe>
    </div>
    
  )
}

