import React from 'react';

export default function Section(props) {

  return (
		<div className=' border-2 border-solid rounded-md m-5 bg-zinc-200'>
			<p className='text-3xl m-10 font-bold md:text-4xl'>{props.title}</p>
			<p className=' m-10' dangerouslySetInnerHTML={{__html:props.description}}></p>
		</div>
  )
}
