import React from 'react'
import { Outlet } from 'react-router-dom'
import Sidebar from './features/layout/Sidebar'

export default function Layout() {
  return (
    <div className='flex flex-row bg-white h-screen w-screen overflow-hidden'>
        <Sidebar/>
        {<Outlet/>}
        
    </div>
  )
}
