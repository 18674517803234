import React from 'react';
import python from '../../../resources/icons/python.png';
import scikitLearn from '../../../resources/icons/scikit-learn.png';
import pandas from '../../../resources/icons/pandas.png';
import numpy from '../../../resources/icons/numpy.png';
import scipy from '../../../resources/icons/scipy.png';
import matplotlib from '../../../resources/icons/matplotlib.png';
import seaborn  from '../../../resources/icons/seaborn.svg';

export default function PoweredBy(props) {

 const height=100;
 const width=100;
 const heightB=70;
 const widthB=70;

  return (
    <div className="powered-by-container">
        <span><i>Powered by</i></span>
        <div className='icons-container'>
            <div className="icon">
                <a href='https://www.python.org/' rel="noreferrer" target='_blank'>
                <img src={python} alt='Python'       width={widthB} height={heightB}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://pandas.pydata.org/' rel="noreferrer" target='_blank'>
                <img src={pandas} alt='Pandas'      width={width} height={height}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://numpy.org/' rel="noreferrer" target='_blank'>
                <img src={numpy} alt='NumPy'     width={width} height={height}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://matplotlib.org/' rel="noreferrer" target='_blank'>
                <img src={matplotlib} alt='Matplotlib'    width={widthB} height={heightB}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://scikit-learn.org/stable/' rel="noreferrer" target='_blank'>
                <img src={scikitLearn} alt='Scikit-learn'   width={width} height={height}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://https://scipy.org/' rel="noreferrer" target='_blank'>
                <img src={scipy} alt='Scipy'  width={width} height={height}></img>
                </a>
            </div>
            <div className="icon">
                <a href='https://seaborn.pydata.org/' rel="noreferrer" target='_blank'>
                <img src={seaborn} alt='Seaborn'  width={widthB} height={heightB}></img>
                </a>
            </div>
            
            
        </div>
    </div>
  )
}
